<template>
	<figure class="gallery">
		<div class="container">
			<div class="row row-nogutter gallery__grid">
				<div class="col-xs-8">
					<div class="mockup-monitor">
						<div
							aria-roledescription="carousel"
							aria-live="off"
							aria-label="Desktop screenshots"
							id="desktopCarousel"
							ref="carouselDesktop"
						>
							<swiper
								@swiper="onSwiperDesktop"
								@slideChange="onSlideChange"
								:slides-per-view="1"
								:simulateTouch="false"
								class="gallery__horizontal"
							>
								<swiper-slide
									class="gallery__horizontal__slide"
									v-for="item in this.galleryList"
									:key="item.id"
									role="group"
									aria-roledescription="slide"
									:aria-label="
										galleryList.indexOf(item) + 1 + ' / ' + galleryList.length
									"
								>
									<picture>
										<source
											:media="`(min-width: ${$root.bpMd}px)`"
											:srcset="
												require('@/assets/projects/' + item.desktop + '.jpg')
											"
											width="960"
											height="540"
										/>
										<img
											:srcset="
												require('@/assets/projects/' +
													item.desktop +
													'-m@1.jpg') +
												' 1x, ' +
												require('@/assets/projects/' +
													item.desktop +
													'-m@2.jpg') +
												' 2x'
											"
											:alt="'Screenshot number '+ galleryList.indexOf(item) + 1 + ' of ' + item.name + ' in a desktop viewport'"
											width="400"
											height="225"
											loading="lazy"
										/>
									</picture>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<div class="col-xs-4">
					<div class="mockup-phone">
						<div
							aria-roledescription="carousel"
							aria-live="off"
							aria-label="Smartphone screenshots"
							id="mobileCarousel"
							ref="carouselPhone"
						>
							<swiper
								@swiper="onSwiperPhone"
								@slideChange="onSlideChange"
								:slides-per-view="1"
								:simulateTouch="false"
								class="gallery__horizontal"
							>
								<swiper-slide
									class="gallery__horizontal__slide"
									v-for="item in this.galleryList"
									:key="item.id"
									role="group"
									aria-roledescription="slide"
									:aria-label="
										galleryList.indexOf(item) + 1 + ' / ' + galleryList.length
									"
								>
									<picture>
										<source
											:media="`(min-width: ${$root.bpMd}px)`"
											:srcset="
												require('@/assets/projects/' + item.mobile + '.jpg')
											"
											width="300"
											height="540"
										/>
										<img
											:srcset="
												require('@/assets/projects/' +
													item.mobile +
													'-m@1.jpg') +
												' 1x, ' +
												require('@/assets/projects/' +
													item.mobile +
													'-m@2.jpg') +
												' 2x'
											"
											:alt="'Screenshot number '+ galleryList.indexOf(item) + 1 + ' of ' + item.name + ' in a mobile viewport'"
											width="135"
											height="243"
											loading="lazy"
										/>
									</picture>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
			</div>

			<figcaption class="gallery__caption">
				<div
					aria-roledescription="carousel"
					aria-label="Captions"
					id="captionsCarousel"
					ref="carouselCaption"
				>
					<div aria-live="polite">
						<swiper
							@swiper="onSwiperCaption"
							@slideChange="onSlideChange"
							@slideChangeTransitionEnd="manageKeyboard"
							:slides-per-view="1"
							:simulateTouch="false"
							class="gallery__horizontal"
						>
							<swiper-slide
								class="gallery__horizontal__slide"
								v-for="item in this.galleryList"
								:key="item.id"
								role="group"
								aria-roledescription="slide"
								:aria-label="
									galleryList.indexOf(item) + 1 + ' / ' + galleryList.length
								"
							>
								<div v-html="item.caption"></div>
							</swiper-slide>
						</swiper>
					</div>
					<div class="gallery__nav">
						<button
							@click="this.onPrevClick()"
							ref="prevButton"
							class="gallery__nav__btn"
							:disabled="leftArrowDisabled"
							aria-controls="captionsCarousel desktopCarousel mobileCarousel"
							aria-label="Previous Slide"
						>
							<font-awesome-icon icon="chevron-left" />
						</button>
						<button
							@click="this.onNextClick()"
							ref="nextButton"
							class="gallery__nav__btn"
							:disabled="rightArrowDisabled"
							aria-controls="captionsCarousel desktopCarousel mobileCarousel"
							aria-label="Next Slide"
						>
							<font-awesome-icon icon="chevron-right" />
						</button>
					</div>
				</div>
			</figcaption>
		</div>
	</figure>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import keyboard from "@/mixins/keyboard.js";
//import SwiperCore, { Controller } from "swiper";
//SwiperCore.use([Controller]);
import "swiper/swiper.scss";
//import "swiper/components/a11y/a11y.scss";

export default {
	name: "Gallery",
	components: {
		Swiper,
		SwiperSlide,
	},
	mixins: [keyboard],
	props: {
		galleryList: undefined,
	},
	data() {
		return {
			rightArrowDisabled: false,
			leftArrowDisabled: false,
			swiperDesktop: null,
			swiperPhone: null,
			swiperCaption: null,
			hasPrev: false,
			hasNext: true,
			interval: null,
			scrollWidth: 0,
			left: 0,
			progress: 0,
			index: 0,
		};
	},
	mounted() {},
	methods: {
		onSwiperDesktop(swiper) {
			this.swiperDesktop = swiper;
		},
		onSwiperPhone(swiper) {
			this.swiperPhone = swiper;
		},
		onSwiperCaption(swiper) {
			this.swiperCaption = swiper;
			this.checkArrows(swiper);
		},
		onPrevClick() {
			this.swiperCaption.slidePrev();
		},
		onNextClick() {
			this.swiperCaption.slideNext();
		},
		onSlideChange(swiper) {
			const self = this;
			if (swiper == self.swiperDesktop)
				setTimeout(function () {
					self.swiperPhone.slideTo(swiper.activeIndex);
				}, 70);
			else if (swiper == self.swiperPhone)
				setTimeout(function () {
					self.swiperCaption.slideTo(swiper.activeIndex);
				}, 70);
			else if (swiper == self.swiperCaption)
				setTimeout(function () {
					self.swiperDesktop.slideTo(swiper.activeIndex);
				}, 70);
			self.checkArrows(swiper);
		},
		manageKeyboard() {
			const carousels = [this.$refs.carouselDesktop, this.$refs.carouselPhone, this.$refs.carouselCaption]
			carousels.forEach((c) => {
				this.toggleTab(c.querySelectorAll('.swiper-slide'), false)
				this.toggleTab(c.querySelectorAll('.swiper-slide.swiper-slide-active'), true)
			})
		},
		checkArrows(swiper) {
			this.rightArrowDisabled = false;
			this.leftArrowDisabled = false;

			if (swiper.isEnd) this.rightArrowDisabled = true;
			else if (swiper.isBeginning) this.leftArrowDisabled = true;
		},
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.gallery {
	position: relative;
	padding-bottom: 8rem;
	--monitor-dots: hsl(
		var(--project-color-h),
		var(--project-color-s),
		var(--project-color-l)
	);
}

$bp-nav: $bp-md + 30;
$bp-nav-minus: $bp-md + 29;

.gallery__nav {
	position: absolute;
	top: 50%;
	height: 0;
	overflow: visible;
	z-index: 3;
	left: -$container-padding;
	right: -$container-padding;
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	@media (min-width: $bp-nav) {
		left: -10rem;
		right: -10rem;
	}

	.gallery__nav__btn {
		color: $text-color;
		width: 6rem;
		height: 8rem;
		margin-top: -4rem;
		transition: opacity 0.2s ease, background-color 0.2s ease;
		display: flex;
		align-items: center;
		//flex-grow: 1;
		padding: 1rem 1rem;
		font-size: 2.5rem;

		@media (max-width: $bp-nav-minus) {
			&:first-child {
				justify-content: flex-start;
			}

			&:last-child {
				justify-content: flex-end;
			}
		}

		@media (min-width: $bp-nav) {
			width: 8rem;
			height: 8rem;
			padding: 0;
			border-radius: 50%;
			flex-grow: 0;
			font-size: 4rem;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			@media (min-width: $bp-nav) {
				background-color: $grey1;
			}
		}

		&[disabled] {
			opacity: 0.2;
			background-color: transparent;
		}
	}
}

.gallery__grid {
	@media (max-width: $bp-sm-max) {
		margin: 0 -3rem !important;
	}

/* 	.mockup-monitor,
	.mockup-phone {
		//display: table;
	} */

	.mockup-monitor {
		max-width: 960px;
		margin-right: -25%;
		z-index: 1;
	}
	.mockup-phone {
		margin-top: 4rem;
		max-width: 300px;
		margin-right: 0;
		margin-left: auto;
		z-index: 2;

		@media (min-width: $bp-md) {
			margin-top: 8rem;
		}
	}
}

.gallery__horizontal {
	margin: 0;

	> .v-hl-btn {
		@media (max-width: $bp-lg + 10) {
			transform: none !important;
		}
	}

	.gallery__horizontal__slide {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		//padding: 0 1rem;

		div {
			height: inherit;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			margin-bottom: 0;
		}
	}
}

.gallery__caption {
	position: relative;
	margin-top: 4rem;
	@include text-size($font-size-small);

	@media (min-width: $bp-md) {
		margin-left: 4rem;
		margin-right: 4rem;
		padding: 0 2rem;
	}

	@media (min-width: $bp-nav) {
		margin-left: 0;
		margin-right: 0;
		position: static;
	}
}
</style>
